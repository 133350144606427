<template>
    <div :style='{"font-size":"7pt", "line-height": "normal"}'>
        <div :style='{...estilos.tablaBorde, ...{"text-align":"center", "font-weight": "400", "background-color": "#D3D3D3"}}'>
            Aparatos y sistemas alterados
        </div>
        <div>
            {{datos?datos.aparatos.split(',').join(', '):''}}
        </div>
        <div :style='{...estilos.tablaBorde, ...{"text-align":"center", "font-weight": "400", "background-color": "#D3D3D3"}}'>
            Características de la orina
        </div>
        <div>
            {{datos?datos.orina.split(',').join(', '):''}}
        </div>
        <div :style='{...estilos.tablaBorde, ...{"text-align":"center", "font-weight": "400", "background-color": "#D3D3D3"}}'>
            Evacuación
        </div>
        <div>
            {{datos?datos.evacuacion:''}} <span v-if="datos && datos.evacuacionMucosidad">, con mucosidad </span> <span v-if="datos&&datos.evacuacionSangre">, con sangre </span>
        </div>
    </div>
</template>

<script>
import { printEnfe } from '@/mixins/enfermeria/printEnfeMixin.js'
export default {
    mixins: [printEnfe],

}
</script>

<style>

</style>